import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IViewModel } from '../../viewmodels/_viewModel.interfaces';
import { EntityType } from '../EntityDetailPopup/entityDetailPopup.enums';
import { DropdownEntityType } from './BoundDropDown/types';
import { dateTimeTypeEnum } from '../EntityDetailPopup/CustomField/CustomFieldEditor';

//input fields

export interface IGeneralProps {
	onChange: (value: any) => void;
	required?: boolean;
	InputActionObject?: IInputActionProps;
	readOnly?: boolean;
	hasError?: boolean;
}

export interface IInputActionProps {
	icon: IconProp;
	onClick: () => any;
}

export interface IDropdownComponentProps extends IGeneralProps {
	options: IOption[] | undefined;
	value: any | any[];
	searchCallback?: (value: string) => void
}

export interface IDropdownMenuProps extends IDropdownComponentProps {
	closeFunction: () => void
}

export interface IDropdownOptionProps extends Omit<IOption, 'value'> {
	selected?: boolean
	onClick?: () => void
}

export interface IOption {
	value: any;
	label: string;
	hidden?: boolean;
}

export interface IDateTimeInputProps extends IGeneralProps {
	type: DateTimeType;
	value: string;
}

// export interface IBooleanInputComponentProps extends Omit<IGeneralProps, 'onChange'>
export interface IBooleanInputComponentProps extends IGeneralProps {
	value: string | boolean;
	booleanLabels?: [string, string];
	storeBooleanLabelsAsValue?: boolean;
}

export interface IHyperlinkInputProps extends IGeneralProps {
	type: LinkType;
	value: string;
}

export interface INumberInputProps extends IGeneralProps {
	value: number;
	allowDecimals?: boolean;
	min?: number;
	max?: number;
}

export interface ITextareaProps extends ITextInputProps {
	expanded?: boolean;
}

export interface ITextInputProps extends IGeneralProps {
	value: string;
}

export enum SelectType {
	select = 'select',
	multiSelect = 'multiSelect',
}

export enum DateTimeType {
	date = 'date',
	time = 'time',
	'datetime-local' = 'datetime-local',
}

export enum LinkType {
	url = 'url',
	email = 'email',
	tel = 'tel',
}

//bound input field layouts

export interface IBoundInputRowProps {
	key?: any;
	label: string;
	vm: IViewModel<any>;
	model: any;
	modelValuePropertyName: string;
	readonly?: boolean;
	InputAction?: JSX.Element;
	inputActionAlwaysVisible?: boolean;
	errorMessage?: string;
}

export interface IBoundInputRowWrapperProps extends IBoundInputRowProps {
	InputField: JSX.Element;
}

export interface IBoundTextRowProps extends IBoundInputRowProps {}

export interface IBoundNumberRowProps extends IBoundInputRowProps {
	allowDecimals?: boolean
	min?: number;
	max?: number;
}

export interface IBoundHyperlinkRowProps extends IBoundTextRowProps {
	type: LinkType;
}

export interface IBoundTextareaRowProps extends IBoundTextRowProps {}

export interface IBoundBooleanRowProps extends IBoundInputRowProps {
	booleanLabels?: [string, string];
	storeBooleanLabelsAsValue?: boolean;
	noDeleteButton?: boolean;
}

export interface IBoundDateTimeRowProps extends IBoundInputRowProps {
	type: DateTimeType;
}

export interface IDictionaryBoundDropDownRowProps extends IBoundInputRowProps {
	DDEntityType: DropdownEntityType;
	readId?: any;
}

export interface IBoundDropdownRowProps extends IBoundInputRowProps {
	type: SelectType;
	options: IOption[] | undefined;
}

export interface IBoundEntityDropdownRowProps extends Omit<IBoundDropdownRowProps, 'type'> {
	entityType: EntityType
}
