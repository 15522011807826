import { useEffect } from 'react';
import { GridComponents, PanelMain } from '../../components';
import { CompanyAnnotationViewCollectionViewModel } from '../../viewmodels/Collections/CompanyAnnotationViewCollectionViewModel';

const instanceName = 'BlaBlaBla';
const companyId = /*scheursbv2*/ '10428abd-6975-ed11-a816-002248816633';

export const TestPage6 = (props) => {
	const cmAnnotations = CompanyAnnotationViewCollectionViewModel(instanceName);

	useEffect(() => {
		cmAnnotations.read(companyId);
	}, []);

	function buildGrid() {
		let items = cmAnnotations.items.map((item, index) => {
			return (
				<tr key={`tr_${cmAnnotations.items[index].entity_Id}`}>
					<td valign='top'>
						<textarea
							defaultValue={item.entity_Text!}
							onBlur={(event) => handleTextFieldBlur(index, event.target.value)}
						/>
					</td>
					<td>
						<button onClick={() => deleteItem(index)}>X</button>
					</td>
				</tr>
			);
		});

		return items;
	}

	const deleteItem = async (index) => {
		let success = await cmAnnotations.deleteItem(index);

		if (!success) {
			alert('Geef de developer maar de schuld!!!1!');
		}
	};

	const handleTextFieldBlur = async (index, newValue: string) => {
		//Updates the data...

		console.log(`Text field at index ${index} lost focus. New value: ${newValue}`);
		let oldValue = cmAnnotations.items[index].entity_Text;

		if (oldValue === null) oldValue = '';
		if (oldValue.length === 0 && newValue.length === 0) return;

		//Normalize
		oldValue = oldValue.replace(/\r\n/g, '\n');
		newValue = newValue.replace(/\r\n/g, '\n');

		if (oldValue === newValue) return; //Nothing to see here

		let success = await cmAnnotations.updateItem(index, newValue);

		if (!success) {
			alert('Geef de developer maar de schuld!!!1!');
		}
	};

	const insertItem = async () => {
		await cmAnnotations.insertItem(companyId);
	};

	return (
		<PanelMain>
			<GridComponents.Grid>
				<GridComponents.Column size='whole'>
					<div>
						<h1>Mijn todo's</h1>
						<br />
						<button onClick={() => insertItem()}>Add new</button>
						<br />
						<br />
						<table>
							<tbody>{buildGrid()}</tbody>
						</table>
					</div>
				</GridComponents.Column>
			</GridComponents.Grid>
		</PanelMain>
	);
};
