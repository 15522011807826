import React, { useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

/*TODO: only import icons we're actually going to use,
	perhaps write a custom file where we do this.*/
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

import './style.scss';

// TODO: Leave comments in tact for now, remove later
// import './extensions/ObjectExtensions'; PLEASE LEAVE INTACT
// import './extensions/StringExtensions'; PLEASE LEAVE INTACT

import './localization/I18n';
import { useTranslation } from 'react-i18next';

import {
	SettingsPage,
	DashboardPage,
	LoginReset,
	ResetPassword,
	SuccessfulRequest,
	SuccessfulPasswordReset,
	TasksPage,
	OpportunityPage,
	CompaniesPage,
	NotFoundPage,
	AgendaPage,
	LoginPage,
} from './pages';

import DownloadPage from './pages/Download/Download';
import { AuthenticatedRoutes } from './globals/AuthenticatedRoute';
import { Fragment } from 'react';
import { Dialog } from './components/Dialog/Dialog';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { TestPage } from './pages/Test/TestPage';
import { TestPage2 } from './pages/Test2/TestPage2';
import { TestPage3 } from './pages/Test3/TestPage3';
import { TestPage4 } from './pages/Test4/TestPage4';
import { TestPage5 } from './pages/Test5/TestPage5';
import { TestPage6 } from './pages/Test6/TestPage6';
import { SetupPage } from './pages/OIDC/SetupPage';
import { FinishPage } from './pages/OIDC/FinishPage';
import { hasModule, moduleNames } from './utilities/authProvider';

const auth = `/Authentication`;

library.add(fal, far, fas);

function App() {
	useTranslation();
	const dialog = useAppSelector((state) => state.dialog);
	const dispatch = useAppDispatch();

	return (
		<Fragment>
			<Router>
				<Routes>
					<Route
						path={`/login`}
						element={<LoginPage />}
					/>

					<Route
						path={`${auth}/loginReset`}
						element={<LoginReset />}
					/>
					<Route
						path={`${auth}/resetPassword`}
						element={<ResetPassword />}
					/>
					<Route
						path={`${auth}/successfulRequest`}
						element={<SuccessfulRequest />}
					/>
					<Route
						path={`${auth}/successfulPasswordReset`}
						element={<SuccessfulPasswordReset />}
					/>
					<Route
						path={`/download`}
						element={<DownloadPage />}
					/>
					<Route
						path='/oidc/setup'
						element={<SetupPage />}
					/>
					<Route
						path='/oidc/finish'
						element={<FinishPage />}
					/>
					<Route
						path={`/`}
						element={<AuthenticatedRoutes />}
					>
						<Route
							index
							element={<DashboardPage />}
						/>
						<Route
							path={`test`}
							element={<TestPage />}
						/>
						<Route
							path={`test2`}
							element={<TestPage2 />}
						/>
						<Route
							path={`test3`}
							element={<TestPage3 />}
						/>
						<Route
							path={`test4`}
							element={<TestPage4 />}
						/>
						<Route
							path={`test5`}
							element={<TestPage5 />}
						/>
						<Route
							path={`test6`}
							element={<TestPage6 />}
						/>
						<Route
							path={`settings/*`}
							element={<SettingsPage />}
						/>
						<Route
							path={`companies`}
							element={<CompaniesPage />}
						/>
						<Route
							path={`tasks`}
							element={<TasksPage />}
						/>
						<Route
							path={`opportunities`}
							element={<OpportunityPage />} //TODO: missing module page
						/>
						<Route
							path='agenda'
							element={<AgendaPage />}
						/>
					</Route>
					<Route
						path='*'
						element={<NotFoundPage />}
					/>
				</Routes>
			</Router>
			{dialog.length > 0 && (
				<Dialog
					dialogState={dialog[0]}
					dispatch={dispatch}
				/>
			)}
		</Fragment>
	);
}

export function useRouterListener() {
	const navigate = useNavigate();

	const handleKeyPress = useCallback(
		(event) => {
			const tag = event.target.tagName;
			if (['INPUT', 'TEXTAREA', 'SELECT', 'OPTION'].includes(tag)) return;

			switch (event.key) {
				case '1':
					navigate('/');
					break;
				case '2':
					navigate('/agenda');
					break;
				case '3':
					navigate('/companies');
					break;
				case '4':
					navigate('/tasks');
					break;
				case '5':
					const hasOpportunityModule = hasModule(moduleNames.opportunity);
					if (hasOpportunityModule) navigate('/opportunities');
					break;
				case '6':
					navigate('/settings');
					break;
				default:
					break;
			}
		},
		[navigate]
	);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [handleKeyPress]);

	return null;
}

export default App;
