import { api } from '../../api/_Executor';
import { PrioritizedCompanyQuery } from '../../business/Query/PrioritizedCompanyQuery';
import { BaseQuery } from '../../business/Query/_BaseQuery';
import { FilterField } from '../../business/Query/_enums';
import { CompanyTableDefinition } from '../../components/DynamicTable/dynamicTable.index';
import { Settings } from '../../globals/settings';
import { PrioritizedCompanyView } from '../../model/Views/PioritizedCompanyView';
import { BaseViewCollectionViewModel } from './_BaseViewCollectionViewModel';
import { ICollectionViewModel } from './_collectionViewModel.interfaces';

export enum PrioritizedCompanyCollectionMode {
	my = 0,
	all = 1,
}

export function PrioritizedCompanyViewCollectionViewModel<T = PrioritizedCompanyView, Q extends BaseQuery = PrioritizedCompanyQuery>(
	instanceName: string,
	ignoreRepresentative_id?: boolean,
	take: number = Settings.PAGINATION_DEFAULT_TAKE
): ICollectionViewModel<T, Q> {
	function switchMode(modus: number, query: PrioritizedCompanyQuery) {
		query.mustBeActive = true;
		query.filterFields = FilterField.City | FilterField.Name | FilterField.PostalCode | FilterField.PostalCodeArea;

		switch (modus) {
			case PrioritizedCompanyCollectionMode.my: {
				query.queryAllAccounts = false;
				break;
			}
			case PrioritizedCompanyCollectionMode.all: {
				query.queryAllAccounts = true;
				break;
			}
		}
	}

	const apiReader = api.prioritizedCompany.viewAsync;
	const query = new PrioritizedCompanyQuery() as Q;
	const table = CompanyTableDefinition;
	const canSearch: boolean = true;
	const canSwitch: boolean = true;
	const collectionMode = PrioritizedCompanyCollectionMode;

	const cvm = BaseViewCollectionViewModel<T, Q>(instanceName, apiReader, ignoreRepresentative_id, query, switchMode, 'entity_Id', undefined, false, take);

	return {
		instanceName,
		isSearchable: canSearch,
		isSwitchable: canSwitch,
		read: cvm.doRead,
		pageNext: cvm.doPageNext,
		switchRepresentative: cvm.doSwitchRepresentative,
		refresh: cvm.doQuery,
		getProperties: cvm.getProperties,
		toggleSortOrder: cvm.toggleSortOrder,
		pkName: cvm.pkName,
		skName: cvm.skName,
		items: cvm.items,
		query: cvm.query,
		defaultTable: table,

		search: cvm.doSearch,
		switchMode: cvm.doSwitchMode,
		readAll: cvm.doReadAll,
		searchTerm: cvm.searchTerm,
		mode: cvm.mode,
		modeEnum: collectionMode,
	};
}

// import { api } from "../../api/_Executor";
// import { PrioritizedCompanyQuery } from "../../business/Query/PrioritizedCompanyQuery";
// import { PrioritizedOpportunityQuery } from "../../business/Query/PrioritizedOpportunityQuery";
// import { PrioritizedTaskQuery } from "../../business/Query/PrioritizedTaskQuery";
// import { TaskQuery } from "../../business/Query/TaskQuery";
// import { FilterField } from "../../business/Query/_enums";
// import { PrioritizedCompanyView } from "../../model/Views/PioritizedCompanyView";
// import { PrioritizedOpportunityView } from "../../models/BusinessObjects_View/PrioritizedOpportunityView";
// import { PrioritizedTaskView } from "../../models/BusinessObjects_View/PrioritizedTaskView";
// import { UserManager } from "../../utilities/UserManager";
// import { BaseViewCollectionViewModel } from "./_BaseViewCollectionViewModel";

// export enum PrioritizedCompanyCollectionMode {
// 	All = 0,
// 	My = 1,
// }

// export function PrioritizedCompanyViewCollectionViewModel(instanceName:string) {
// 	function switchMode(modus:number, query:PrioritizedCompanyQuery) {
// 		query.mustBeActive = true;
// 		query.representative_Id = cm.getRepresentativeId();
// 		query.filterFields = FilterField.City | FilterField.Name | FilterField.PostalCode | FilterField.PostalCodeArea;

// 		switch (modus) {
// 			case PrioritizedCompanyCollectionMode.My: {
// 				query.queryAllAccounts = false;
// 				break;
// 			}
// 			case PrioritizedCompanyCollectionMode.All: {
// 				query.queryAllAccounts = true;
// 				break;
// 			}
// 		}
// 	}

// 	const switchModeFunc = switchMode;
// 	const apiReader = api.prioritizedCompany.viewAsync;
// 	const query = new PrioritizedCompanyQuery();

// 	const cvm = BaseViewCollectionViewModel<PrioritizedCompanyView, PrioritizedCompanyQuery>(
// 		instanceName,
// 		apiReader,
// 		switchModeFunc,
// 		query,
// 		'entity_Id',
// 		false,
// 		20
// 	);

// 	return {
// 		switchMode: cm.doSwitchMode,
// 		read: cm.doRead,
// 		readAll: cm.doReadAll,
// 		pageNext: cm.doPageNext,
// 		search: cm.doSearch,
// 		refresh: cm.doRefresh,
// 		getProperties: cm.getProperties,
// 		items: cm.items,
// 		mode: cm.mode,
// 		searchTerm: cm.searchTerm,
// 		query: cm.query,

// 		instanceName
// 	}
// }

// // import { useState } from "react";
// // import { api } from "../../api/_Executor";
// // import { PrioritizedCompanyQuery } from "../../business/Query/PrioritizedCompanyQuery";
// // import { FilterField } from "../../business/Query/_enums";
// // import { EntityType } from "../../globals/enums";
// // import { PrioritizedCompanyView } from "../../model/Views/PioritizedCompanyView";
// // import { UserManager } from "../../utilities/UserManager";
// // import { getObjProps, isNotNothing } from "../../utilities/utils";

// // const userManager = new UserManager();

// // export function PrioritizedCompanyViewCollectionViewModel() {
// // 	const entityType = EntityType.account || EntityType.lead;

// // 	const [allowDelete, setAllowDelete] = useState<boolean>(false);
// // 	const [allowNew, setAllowNew] = useState<boolean>(false);
// // 	const [searchText, setSearchText] = useState<string>('');

// // 	const [items, setItems] = useState<PrioritizedCompanyView[]>();

// // 	const q = new PrioritizedCompanyQuery();
// // 	const [query, setQuery] = useState<PrioritizedCompanyQuery>(q);

// // 	async function init(id?:string) {
// // 		setQuery(q);
// // 		await refresh();
// // 	}

// // 	function pageNext() {

// // 	}

// // 	async function refresh() {
// // 		var uid = userManager.getCurrentUserId();

// // 		if (uid !== undefined && uid !== null) {
// // 			query.representative_Id = uid;
// // 		}

// // 		var a = FilterField.City | FilterField.Name | FilterField.PostalCode | FilterField.PostalCodeArea;

// // 		query.filterFields = a;
// // 		query.mustBeActive = true;
// // 		query.queryAllAccounts = true;
// // 		query.searchTerm = '';
// // 		query.skip = 0;
// // 		query.take = 9999;

// // 		var data = await api.prioritizedCompany.viewAsync(query);
// // 		if (data === undefined) return;
// // 		setItems(data);
// // 	}

// // 	function M() {
// // 		return getObjProps<PrioritizedCompanyView>();
// // 	}

// // 	return {
// // 		init,
// // 		refresh,
// // 		props: M,
// // 		items,
// // 	}
// // }
