import { generateOptionsProp } from '../BoundInputRows/BoundInputRows';
import DropdownComponent from '../DropdownInput';
import { t } from 'i18next';
import {
	IBoundDropDownInputProps,
	ICVMDictionaryEntry,
	IEnumDictionaryEntry,
	IReduxBoundDropDownInputProps,
	IReduxDictionaryEntry,
	DictionaryEntryType,
	dropdownEntityTypeDictionary,
	IDefaultDictionaryEntry,
} from './types';
import { useAppSelector } from '../../../store/hooks';
import { CVMBoundDropDownInput } from './CVMBoundDropdownInput';
import { IOption } from '../inputField.types';

export function enumToOptionsArray(enumerator, labelPrefix?: string) {
	const options = Object.keys(enumerator)
		.filter((value) => isNaN(Number(value)) === false)
		.map((key) => {
			let label = '';
			if (labelPrefix !== undefined) label += labelPrefix;
			label += enumerator[key];
			return { label: t(label), value: key };
		});

	return options;
}

export function DictionaryBoundDropDownInput(props: IBoundDropDownInputProps) {
	const DDETDEntry = dropdownEntityTypeDictionary[props.DDEntityType];

	switch (DDETDEntry.type) {
		case DictionaryEntryType.CVM:
			const CVMEntry = DDETDEntry as ICVMDictionaryEntry;

			return (
				<CVMBoundDropDownInput
					{...props}
					{...CVMEntry}
				/>
			);
		case DictionaryEntryType.redux:
			const reduxEntry = DDETDEntry as IReduxDictionaryEntry;

			return (
				<ReduxBoundDropDownInput
					{...props}
					{...reduxEntry}
				/>
			);
		case DictionaryEntryType.enum:
		case DictionaryEntryType.default:
			let options: IOption[] = [];
			if (DDETDEntry.type === DictionaryEntryType.enum) {
				const enumEntry = DDETDEntry as IEnumDictionaryEntry;
				options = enumToOptionsArray(enumEntry.enum, enumEntry.labelPrefix);
			} else {
				const defaultEntry = DDETDEntry as IDefaultDictionaryEntry;
				options = defaultEntry.options;
			}

			return (
				<DropdownComponent
					value={props.value}
					onChange={props.onChange}
					options={options}
					readOnly={props.readOnly}
					hasError={props.hasError}
				/>
			);
	}
}

function ReduxBoundDropDownInput(props: IReduxBoundDropDownInputProps) {
	const items = useAppSelector(props.appSelectorCallback);

	const options = generateOptionsProp(items, props.valuePropertyName, props.labelPropertyName);

	return (
		<DropdownComponent
			value={props.value}
			options={options}
			onChange={props.onChange}
			readOnly={props.readOnly}
			hasError={props.hasError}
		/>
	);
}
