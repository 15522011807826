/* UNMODIFIED */
export interface ICompanyAnnotationView {
	$type: string | null;
    entity_Id: string | null;
    entity_CreatedBy_Id: string | null;
    entity_CreatedBy_Name: string | null;
    entity_Text: string | null;
    entity_CreatedDate: Date | null;
    entity_LasteModifiedDate: Date | null;
    entity_Company_Id: string | null;
    entity_Company_Name: string | null;
}

export class CompanyAnnotationView implements ICompanyAnnotationView {
	public $type: string | null = null;
    public entity_Id: string | null = null;
    public entity_CreatedBy_Id: string | null = null;
    public entity_CreatedBy_Name: string | null = null;
    public entity_Text: string | null = null;
    public entity_CreatedDate: Date | null = null;
    public entity_LasteModifiedDate: Date | null = null;
    public entity_Company_Id: string | null = null;
    public entity_Company_Name: string | null = null;
}
