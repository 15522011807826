import { AddressQuery } from '../../business/Query/AddressQuery';
import { AppointmentQuery } from '../../business/Query/AppointmentQuery';
import { CompanyAnnotationQuery } from '../../business/Query/CompanyAnnotationQuery';
import { ContactQuery } from '../../business/Query/ContactQuery';
import { PrioritizedOpportunityQuery } from '../../business/Query/PrioritizedOpportunityQuery';
import { PrioritizedTaskQuery } from '../../business/Query/PrioritizedTaskQuery';
import { AddressView } from '../../models/BusinessObjects_View/AddressView';
import { AppointmentView } from '../../models/BusinessObjects_View/AppointmentView';
import { CompanyAnnotationView } from '../../models/BusinessObjects_View/CompanyAnnotationView';
import { ContactView } from '../../models/BusinessObjects_View/ContactView';
import { PrioritizedOpportunityView } from '../../models/BusinessObjects_View/PrioritizedOpportunityView';
import { PrioritizedTaskView } from '../../models/BusinessObjects_View/PrioritizedTaskView';
import { AddressViewCollectionViewModel } from '../Collections/AddressViewCollectionViewModel';
import { AppointmentViewCollectionViewModel } from '../Collections/AppointmentViewCollectionViewModel';
import { useFileViewCollectionViewModel } from '../Collections/FileViewCollectionViewModel';
import { CompanyAnnotationViewCollectionViewModel } from '../Collections/CompanyAnnotationViewCollectionViewModel';
import { ContactViewCollectionViewModel } from '../Collections/ContactViewCollectionViewModel';
import { PrioritizedOpportunityViewCollectionViewModel } from '../Collections/PrioritizedOpportunityViewCollectionViewModel';
import { PrioritizedTaskViewCollectionViewModel } from '../Collections/PrioritizedTaskViewCollectionViewModel';
import { ICollectionViewModel } from '../Collections/_collectionViewModel.interfaces';
import CompanyViewModel, { ICompanyViewModel } from '../CompanyViewModel';
import { FileView } from '../../models/BusinessObjects_View/FileView';
import { FileQuery } from '../../business/Query/FileQuery';

export interface ICompanyRelatedModel extends ICompanyViewModel {
	contacts: ICollectionViewModel<ContactView, ContactQuery>;
	addresses: ICollectionViewModel<AddressView, AddressQuery>;
	opportunities: ICollectionViewModel<PrioritizedOpportunityView, PrioritizedOpportunityQuery>;
	tasks: ICollectionViewModel<PrioritizedTaskView, PrioritizedTaskQuery>;
	annotations: ICollectionViewModel<CompanyAnnotationView, CompanyAnnotationQuery>;
	appointments: ICollectionViewModel<AppointmentView, AppointmentQuery>;
	file: ICollectionViewModel<FileView, FileQuery>;
}

export default function CompanyRelatedViewModel(groupName: string): ICompanyRelatedModel {
	function getName(instanceName: string) {
		return `${groupName}_${instanceName}`;
	}

	const mainViewModel = CompanyViewModel(getName('CompanyViewModel'));

	const contacts = ContactViewCollectionViewModel('contacts', true);
	const addresses = AddressViewCollectionViewModel('addresses', true);
	const opportunities = PrioritizedOpportunityViewCollectionViewModel(getName('opportunities'), true);
	const tasks = PrioritizedTaskViewCollectionViewModel(getName('tasks'), true);
	const annotations = CompanyAnnotationViewCollectionViewModel('annotations', true);
	const appointments = AppointmentViewCollectionViewModel('appointments', true);
	const file = useFileViewCollectionViewModel('file', true);

	return {
		...mainViewModel,
		instanceName: groupName,

		contacts,
		addresses,
		opportunities,
		tasks,
		annotations,
		appointments,
		file,
	};
}
