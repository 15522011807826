import React, { useEffect, useState, useCallback, Fragment } from 'react';
import styles from './testPage.module.scss';
import { GridComponents, PanelMain } from '../../components';
import { QuickAnnotationForm } from '../../components/EntityDetailPopup/QuickAnnotationForm/QuickAnnotationForm';
import CompanyViewModel from '../../viewmodels/CompanyViewModel';
import { EditMethod } from '../../components/EntityDetailPopup/entityDetailPopup.interfaces';
import { EntityDetailPopupConfigured } from '../../components/EntityDetailPopup/EntityDetailPopup';
import { EntityType } from '../../components/EntityDetailPopup/entityDetailPopup.enums';
import QuickAnnotationViewModel from '../../viewmodels/QuickAnnotationViewModel';
import { QuickAnnotationType } from '../../components/EntityDetailPopup/entityDetailPopup.tabConfigurations';
import useQuickAnnotationViewModel from '../../viewmodels/QuickAnnotationViewModel';
import authProvider from '../../utilities/authProvider';

export function TestPage() {
	const [popupVisible, setPopupVisible] = useState(true);

	const openPopup = useCallback(() => {
		setPopupVisible(true);
	}, []);

	const closePopup = useCallback(() => {
		setPopupVisible(false);
	}, []);

	return (
		<PanelMain>
			<GridComponents.Column size="whole">
				<div className={styles.toolbar}>
					<h2>
						<span style={{ fontSize: '50px' }}>Q</span>
						uick annotation form
					</h2>

					<button onClick={openPopup}>open QuickAnnotation form</button>

					{popupVisible && (
						<EntityDetailPopupConfigured
							id=""
							editMethod={EditMethod.create}
							closeFunction={closePopup}
							popupEntityType={QuickAnnotationType.QuickAnnotation}
							viewModelInstanceName="testQuickAnnotation"
						/>
					)}
				</div>
			</GridComponents.Column>
		</PanelMain>
	);
}
