import styles from './boundListView.module.scss';
import { t } from 'i18next';
import { useCallback, useState, Fragment, ReactNode, useEffect } from 'react';
import { DynamicTable } from '../../DynamicTable/DynamicTable';
import { ICollectionViewModel } from '../../../viewmodels/Collections/_collectionViewModel.interfaces';
import { SearchBar } from '../../SearchBar/SearchBar';
import { EntityDetailPopupConfigured } from '../../EntityDetailPopup/EntityDetailPopup';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { entityIconDictionary } from '../../EntityDetailPopup/entityDetailPopup.tabConfigurations';
import { EditMethod } from '../../EntityDetailPopup/entityDetailPopup.interfaces';
import { ITableAttribute } from '../../DynamicTable/DynamicTable.types';
import { useAppSelector } from '../../../store/hooks';
import { dateTimeTypeEnum } from '../../EntityDetailPopup/CustomField/CustomFieldEditor';
import { convertSortOrderItemArrayToObjectArray } from '../../../utilities/sortOrder';

export interface IBoundListViewProps {
	parentId?: string;
	cvm: ICollectionViewModel<any, any>;
	cvmRead: () => void;
	tableAttributes: ITableAttribute[];
	headerText?: string;
	entityType: EntityType;
	useCustomFields?: boolean;
	hidePlusButton?: boolean;
	VMInstanceName: string;
	checkEmphasisFunction?: (item) => boolean;
}

type IFieldValuePropNameDictionary = Record<EntityType, string>;

export const fieldValuePropNames: IFieldValuePropNameDictionary = {
	[EntityType.Company]: 'entity_FieldValues',
	[EntityType.Task]: 'task_FieldValues',
	[EntityType.Opportunity]: 'opportunity_FieldValues',
	[EntityType.Appointment]: 'fieldValues',
	[EntityType.Agenda]: 'fieldValues',
	[EntityType.Address]: 'entity_FieldValues',
	[EntityType.Contact]: 'fieldValues',
	[EntityType.Annotation]: 'entity_FieldValues',
	[EntityType.VisitingFrequency]: 'entity_FieldValues',
	[EntityType.File]: 'entity_FieldValues',
};

export const BoundListView = (props: IBoundListViewProps) => {
	const [currentId, setCurrentId] = useState<string>();
	const [popupMethod, setPopupMethod] = useState<EditMethod>(EditMethod.update);
	const [tableAttributes, setTableAttributes] = useState(props.tableAttributes);
	const [tableItems, setTableItems] = useState(props.cvm.items);

	const [FDs, currentRepresentative] = useAppSelector((store) => [store.globals.fieldDefinitions, store.currentRepresentative.currentRepresentative]);

	const rowClickFunction = useCallback(
		(tableItem: any) => {
			setCurrentId(tableItem[props.cvm.pkName]);
		},
		[props.cvm.pkName]
	);

	const closeFunction = useCallback(
		(changed?: boolean) => {
			setCurrentId(undefined);
			if (changed) {
				props.cvmRead();
				setTableItems(props.cvm.items);
			}
		},
		[props]
	);

	const onRowClick = useCallback(
		(tableItem) => () => {
			setPopupMethod(EditMethod.update);
			rowClickFunction(tableItem);
		},
		[rowClickFunction]
	);

	const onPlusClick = useCallback(() => {
		setCurrentId(props.parentId ? props.parentId : '');
		setPopupMethod(EditMethod.create);
	}, [props.parentId]);

	const onChangeRepresentative = useCallback(() => {
		if (props.cvm.switchRepresentative) {
			props.cvm.switchRepresentative();
			setTableItems(props.cvm.items);
		}
	}, [props.cvm]);

	useEffect(() => {
		const newTableAttributes = [...props.tableAttributes];

		if (FDs !== undefined && props.useCustomFields) {
			const FDsForCurrentEntityType = FDs?.filter((FD) => FD.entityType_Name === EntityType[props.entityType]);
			const FDTableAttributes: ITableAttribute[] = FDsForCurrentEntityType.map((FD) => {
				let datetimeType;
				if (FD.$type === 'fieldDefinitionDateTime') {
					datetimeType = dateTimeTypeEnum[FD.type!];
				}

				return { headTitle: FD.label!, property: { name: FD.id! }, type: datetimeType, dontTranslateHeadTitle: true, dontUseSorting: true };
			});

			newTableAttributes.push(...FDTableAttributes);
		}

		setTableAttributes(newTableAttributes);
	}, [props.tableAttributes, props.useCustomFields, props.entityType, FDs]);

	useEffect(() => {
		const newTableItems = props.cvm.items;

		if (props.useCustomFields) {
			const propName = fieldValuePropNames[props.entityType];

			newTableItems.forEach((item) => {
				const fieldValues = item[propName];

				if (fieldValues !== undefined)
					fieldValues.forEach((element) => {
						const [fieldValueId, fieldValue] = element.split(/:(.+)/);

						item[fieldValueId.toLowerCase()] = fieldValue;
					});
			});
		}

		setTableItems(newTableItems);
	}, [props.cvm.items, props.useCustomFields, props.entityType, FDs]);

	const cvmRead = props.cvmRead;

	useEffect(() => {
		if (currentId === undefined) {
			cvmRead();
			setTableItems(props.cvm.items);
		}
	}, [cvmRead, props.parentId]);

	useEffect(() => {
		onChangeRepresentative();
	}, [cvmRead, currentRepresentative]);

	return (
		<Fragment>
			<FilterButtonsRow cvm={props.cvm} />
			<div className={styles.boundListView}>
				<div className={styles.topBar}>
					<div className={styles.headerText}>
						<span className={styles.label}>
							<FontAwesomeIcon
								className={styles.icon}
								icon={entityIconDictionary[props.entityType]}
							/>
							<span className={styles.name}>{props.headerText}</span>
						</span>
					</div>
					<div className={styles.searchBox}>
						{props.cvm.isSearchable && (
							<SearchBar
								border
								searchCallback={props.cvm.search}
							/>
						)}
					</div>
					{!props.hidePlusButton && (
						<FontAwesomeIcon
							className={styles.addButton}
							icon={faPlusCircle}
							size="xl"
							onClick={onPlusClick}
						/>
					)}
				</div>
				<div className={styles.tableContainer}>
					<DynamicTable
						tableAttributes={tableAttributes}
						tableItems={tableItems}
						onRowClick={onRowClick}
						finalItemVisibleCallback={props.cvm.pageNext}
						thClickHandler={props.cvm.toggleSortOrder}
						sortOrderObjectArray={convertSortOrderItemArrayToObjectArray(props.cvm.query.order)}
						checkEmphasisFunction={props.checkEmphasisFunction}
					/>
				</div>
			</div>

			{currentId !== undefined && (
				<EntityDetailPopupConfigured
					id={currentId}
					editMethod={popupMethod}
					popupEntityType={props.entityType}
					closeFunction={closeFunction}
					viewModelInstanceName={`${props.VMInstanceName}`}
				/>
			)}
		</Fragment>
	);
};

const FilterButtonsRow = (props: { cvm: ICollectionViewModel<any, any> }) => {
	let filterButtons: ReactNode = null;

	if (props.cvm.isSwitchable) {
		let modes = Object.values(props.cvm.modeEnum) as string[];

		const halfLength = Math.ceil(modes.length / 2);
		modes = modes.slice(0, halfLength);

		filterButtons = modes.map((mode, i) => (
			<div
				key={i}
				onClick={() => props.cvm.switchMode(i)}
				className={`${styles.filterButton} ${i === props.cvm.mode && styles.active} ${i === props.cvm.mode - 1 && styles.beforeActive}`}
			>
				{t(mode)}
			</div>
		));
	}

	return <div className={styles.filterButtonsRow}>{filterButtons}</div>;
};
