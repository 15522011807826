import styles from './annotationEditor.module.scss';
import AnnotationEditor from './AnnotationEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect } from 'react';
import { CompanyAnnotationViewCollectionViewModel } from '../../../viewmodels/Collections/CompanyAnnotationViewCollectionViewModel';
import { SearchBar } from '../../SearchBar/SearchBar';
import { entityIconDictionary } from '../../EntityDetailPopup/entityDetailPopup.tabConfigurations';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { openConfirm } from '../../Dialog/dialogSlice';
import { IViewModel } from '../../../viewmodels/_viewModel.interfaces';
import { utcDateToLocaleDateString } from '../../../utilities/utils';
import { Account } from '../../../models/Model/Account';
import { Lead } from '../../../models/Model/Lead';

interface IAnnotationEditorListProps {
	vm: IViewModel<Account | Lead>;
	collectionVMPropName: string;
	id: string;
}

const instanceName = 'annotationEditorList';

export function AnnotationEditorList(props: IAnnotationEditorListProps) {
	const cvm = CompanyAnnotationViewCollectionViewModel(instanceName);
	const dispatch = useDispatch();

	useEffect(() => {
		cvm.read(props.id);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.collectionVMPropName]);

	const confirmDelete = useCallback(
		(index: number) => {
			dispatch(
				openConfirm({
					title: 'delete',
					text: 'areYouSureDelete',
					confirmButtons: [
						{
							confirmText: 'delete',
							onClick: async () => await cvm.deleteItem(index),
						},
					],
				})
			);
		},
		[cvm.deleteItem, dispatch]
	);

	const annotationEditors = cvm.items.map((annotation, index) => {
		const formattedDate = utcDateToLocaleDateString(annotation.entity_CreatedDate);
		const text = annotation.entity_Text;

		return (
			<AnnotationEditor
				key={index}
				defaultContent={text}
				labels={{
					left: formattedDate,
					middle: annotation.entity_CreatedBy_Name !== null ? annotation.entity_CreatedBy_Name : '',
				}}
				functions={{
					changeHandler: (content) => cvm.updateItem(index, content),
					deleteHandler: () => confirmDelete(index),
				}}
			/>
		);
	});

	return (
		<div className={styles.annotationEditorList}>
			<div className={styles.listHeader}>
				<div className={styles.headerText}>
					<FontAwesomeIcon
						className={styles.headerIcon}
						icon={entityIconDictionary[EntityType.Annotation]}
					/>
					{props.vm.getProperty(props.vm.getProperties().name)}
				</div>
				<div className={styles.searchBox}>
					{cvm.isSearchable && (
						<SearchBar
							searchCallback={cvm.search}
							border
						/>
					)}
				</div>
				<FontAwesomeIcon
					className={`${styles.icon} ${styles.action}`}
					icon={faPlusCircle}
					size='xl'
					onClick={() => cvm.insertItem(props.id)}
				/>
			</div>
			{annotationEditors}
		</div>
	);
}
