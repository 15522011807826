import { useCallback } from 'react';
import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import { Appointment } from '../models/Model/Appointment';
import { BaseViewModel } from './_BaseViewModel';
import { IViewModel } from './_viewModel.interfaces';

export enum appointmentDropDownPropertyNames {
	company_Id = 'company_Id',
	createdBy_Id = 'createdBy_Id',

	exchangeSynchronizationStatus = 'exchangeSynchronizationStatus',
	appointmentType_Id = 'appointmentType_Id',
	contactWith_Id = 'contactWith_Id',
}

export const extendedType = '$_type'; //Needed in AppointmentFormDetails.

export function convertDateToISOString(date: Date): string {
	const year = date.getFullYear().toString().padStart(4, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');

	return `${year}-${month}-${day}T${hours}:${minutes}`;
}

/** THIS VIEWMODEL IS NOT FINISHED YET */
export default function AppointmentViewModel<T = Appointment>(instanceName: string): IViewModel<T> {
	const entityType = EntityType.appointment;

	const functionDelete = api.activity.deleteByIdAsync;

	const functionRead = async (id: string, includes?: string[]) => {
		const activity = await api.activity.getByIdAsync(id, includes);
		if (activity === null || activity === undefined) return null;

		//Store original entity type. Hacky, we "inject" this into model.
		if (activity.$type) {
			activity[extendedType] = activity.$type;
		}

		//"Convert" to appointment. Needed for customfield validation
		activity.$type = EntityType.appointment.toString();
		return activity as T;
	};

	const functionUpdate = async (entity: Appointment) => {
		if (entity === null || entity === undefined) return undefined;

		let realType = EntityType.appointment.toString();

		if (entity[extendedType]) {
			realType = entity[extendedType];
		}

		if (realType === EntityType.activity.toString()) {
			return await api.activity.promoteAsync(entity);
		}

		return await api.appointment.updateAsync(entity);
	};

	const vm = BaseViewModel<T>(instanceName, entityType, functionRead, functionUpdate, functionDelete);

	function generateModelInstance(id?: string, representativeId?: string): T {
		const m = new Appointment();

		m.$type = EntityType.appointment.toString();
		m.assignedTo_Id = vm.getRepresentativeId();
		m.isClosed = false;
		m.startDateTime = new Date();
		m.startDateTime.setMinutes(0);
		m.endDateTime = new Date();
		m.endDateTime.setHours(m.startDateTime.getHours() + 1);
		m.endDateTime.setMinutes(0);

		if (id) {
			m.company_Id = id;
		}
		if (representativeId) {
			m.assignedTo_Id = representativeId;
		}

		return m as T;
	}

	function create(id?: string, representativeId?: string, params?: any) {
		return vm.doCreate(generateModelInstance(id, representativeId));
	}

	async function read(id) {
		return await vm.doRead(id);
	}

	async function update() {
		return await vm.doUpdate();
	}

	async function del(clearModel: boolean = true) {
		return await vm.doDelete(clearModel);
	}

	const setProperty = useCallback(
		(propName: any, value: any) => {
			vm.setProperty(propName, value);

			const propNameStartDateTime = 'startDateTime';
			const propNameEndDateTime = 'endDateTime';
			const propNameCompanyId = 'company_Id';
			const propNameContactWithId = 'contactWith_Id';

			if (propName === propNameStartDateTime || propName === propNameEndDateTime) {
				let [startTime, endTime] = [new Date(vm.getProperty(propNameStartDateTime)), new Date(vm.getProperty(propNameEndDateTime))];
				if (propName === propNameStartDateTime) startTime = new Date(value);
				else if (propName === propNameEndDateTime) endTime = new Date(value);

				if (startTime > endTime) {
					if (propName === propNameStartDateTime) {
						endTime = new Date(value);
						endTime.setHours(startTime.getHours() + 1);
						endTime.setMinutes(0);

						vm.setProperty(propNameEndDateTime, convertDateToISOString(endTime));
					} else if (propName === propNameEndDateTime) {
						startTime = new Date(value);
						startTime.setHours(startTime.getHours() - 1);

						vm.setProperty(propNameStartDateTime, convertDateToISOString(startTime));
					}
				}
			} else if (propName === propNameCompanyId) {
				vm.setProperty(propNameContactWithId, undefined);
			}
		},
		[vm]
	);

	return {
		entityType,
		model: vm.model,
		fieldDefinitions: vm.fieldDefinitions,
		isValid: vm.isValid,
		isDirty: vm.isDirty,
		hasSavedChanges: vm.hasSavedChanges,
		instanceName: instanceName,
		create,
		read,
		update,
		del,
		setProperty,
		getProperty: vm.getProperty,
		isPropertyValid: vm.isPropertyValid,
		isPropertyReadOnly: vm.isPropertyReadOnly,
		getPropertyErrors: vm.getPropertyErrors,
		getProperties: vm.getProperties,
		getId: vm.getId,
		getType: vm.getType,
		getTypeCased: vm.getTypeCased,
	};
}
