import jwtDecode, { JwtPayload } from 'jwt-decode';
import { RefreshToken } from '../api/UserAPI';

export function setTokenCookie(loginRes): void {
	const token = loginRes.token.replace(/"/g, '');
	const modules = loginRes.modules;

	const maxAge = 34560000; //400 days
	document.cookie = `token=${token}; Path=/; SameSite=None; Secure; expires=${maxAge}`;
	localStorage.setItem('modules', modules);
}

export function clearTokenCookie(): void {
	document.cookie = `token=;`;
	localStorage.setItem('modules', '');
}

export function getTokenFromCookie(): string | undefined {
	const cookieValue = document.cookie
		.split('; ')
		.find((row) => row.startsWith('token='))
		?.split('=')[1];

	if (cookieValue === '') return undefined;

	return cookieValue;
}

export function isJwtExpired() {
	const now = new Date();
	const jwtExpirationDateUTCString = localStorage.getItem('jwtExpirationDate');

	if (jwtExpirationDateUTCString) {
		const jwtExpirationDate = new Date(jwtExpirationDateUTCString);

		if (jwtExpirationDate < now) {
			return true;
		} else {
			return false;
		}
	} else {
		return true;
	}
}

export async function refreshToken() {
	const res = await RefreshToken();
	const json = await res.json();
	if (json.token) setTokenCookie(json);
}

interface SSWJwtPayload extends JwtPayload {
	module: string[] | undefined;
	privilege: string[] | undefined; //SF permissions
	given_name: string | undefined;
	primarysid: string | undefined;
}

export function getUserName(): string | undefined {
	const token = getTokenFromCookie();
	if (token !== undefined) {
		const decoded = jwtDecode<SSWJwtPayload>(token);
		return decoded.given_name;
	}
}

export function getUserId(): string | undefined {
	const token = getTokenFromCookie();
	if (token !== undefined) {
		const decoded = jwtDecode<SSWJwtPayload>(token);
		return decoded.primarysid;
	}
}

export function getPermissions(): string[] | undefined {
	const token = getTokenFromCookie();
	if (token !== undefined) {
		const decoded = jwtDecode<SSWJwtPayload>(token);
		return decoded.privilege;
	}
}

export enum moduleNames {
	personalFrequency = 'PersonalFrequencyModule',
	switchRepresentative = 'SwitchRepresentativeModule',
	export = 'ExportModule',
	bulkCreate = 'BulkCreateModule',
	quickActions = 'QuickActionsModule',
	documentManagement = 'DocumentManagementModule',
	companyGroup = 'CompanyGroupModule',
	opportunity = 'OpportunityModule',
	opportunityStatusReason = 'OpportunityStatusReasonModule',
	appointmentDescription = 'AppointmentDescriptionModule',
	opportunityDescription = 'OpportunityDescriptionModule',
	lead = 'LeadModule',
	hideNewAccountAndPromoteLead = 'HideNewAccountAndPromoteLeadModule',
	quickAnnotation_showAppointmentSettings = 'QuickAnnotation_ShowAppointmentSettings',
}

export function hasModule(moduleName: moduleNames): boolean {
	const modules = localStorage.getItem('modules');

	if (modules === null) return false;

	const hasModule = modules.split(',').includes(moduleName);

	return hasModule;
}

export function newCompanyDisabled(): boolean {
	const hasLeadModule = hasModule(moduleNames.lead);
	const hasHideNewAccountAndPromoteLeadModule = hasModule(moduleNames.hideNewAccountAndPromoteLead);

	return !hasLeadModule && hasHideNewAccountAndPromoteLeadModule;
}

export function isTokenValid(): boolean {
	const token = getTokenFromCookie();

	if (token !== undefined) {
		const decoded = jwtDecode<SSWJwtPayload>(token);

		return decoded.exp !== undefined && decoded.exp >= Math.floor(Date.now() / 1000);
	}

	return false;
}

const authProvider = {
	setTokenCookie,
	clearTokenCookie,
	getTokenFromCookie,
	getUserName,
	getPermissions,
	getUserId,
};

export default authProvider;
