import { Fragment } from "react";
import { globals } from "../../globals/environment";
import { ButtonDropDown, IButtonDropDownMenuItem } from "../ButtonDropDown/ButtonDropDown";
import styles from '../ButtonLogout/buttonLogout.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
//import styles from './buttonLogout.module.scss';

export const DevMenu = () => {
	const navigate = useNavigate();
	
	const pages = [
		'test',
		'test2',
		'test3',
		'test4',
		'test5',
		'test6'
	]

	const links:IButtonDropDownMenuItem[] = pages.map((p, i) => {
		return {
			key: p,
			content: p,
			onClick: (e) => navigate(`/${p}`)
		}
	})

	return (
		<Fragment>
			{globals.environment.isDev() && (
				<ButtonDropDown
					className={styles.logoutButtonContainer}
					key='test'
					items={links}
				>
					<div className={styles.logoutButton}>
						<FontAwesomeIcon icon={['fas', 'code']} />
					</div>
				</ButtonDropDown>
			)}
		</Fragment>
	)
}
