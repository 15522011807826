import { useCallback, useEffect, useRef, useState } from 'react';

import styles from './inputFields.module.scss';
import { IDropdownComponentProps, IDropdownMenuProps, IDropdownOptionProps } from './inputField.types';
import { SearchBar } from '../SearchBar/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {} from '@fortawesome/pro-light-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { t } from 'i18next';

export function DropdownComponent(props: IDropdownComponentProps) {
	const [open, setOpen] = useState(false);

	const inputClick = useCallback(
		(e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();

			if (!props.readOnly) setOpen(true);
		},
		[props.readOnly]
	);

	const findLabel = useCallback(() => {
		if (props.value !== undefined && props.value !== null && props.options !== undefined && props.options?.length > 0) {
			if (Array.isArray(props.value)) {
				const values = props.value as Array<string>;
				const foundOptions = props.options?.filter((option) => values.some((value) => option.value === value));

				const foundLabels = foundOptions.map((option) => option.label);

				return foundLabels;
			} else {
				const foundLabel = props.options.find((option) => option.value.toString() === props.value.toString())?.label;

				if (foundLabel) return foundLabel;
			}
		}

		return '';
	}, [props.options, props.value]);

	const textInputRef = useRef<any>();

	return (
		<div className={styles.dropdownWrapper}>
			<input
				type='text'
				ref={textInputRef}
				className={`${styles.input} ${props.hasError && styles.invalid}`}
				value={findLabel()}
				onFocus={inputClick}
				onClick={inputClick}
				readOnly={props.readOnly || open}
			/>

			{open && (
				<DropdownMenu
					{...props}
					closeFunction={() => setOpen(false)}
				/>
			)}
		</div>
	);
}

function DropdownMenu(props: IDropdownMenuProps) {
	const valueString = props.value ? props.value.toString() : undefined;
	const allowEmptySelection = !Array.isArray(props.value) && !props.required;

	useEffect(() => {
		document.body.addEventListener('click', props.closeFunction);

		return () => {
			document.body.removeEventListener('click', props.closeFunction);
		};
	}, [props.closeFunction]);

	const options = props.options !== undefined ? [...props.options] : [];
	if (allowEmptySelection) options.unshift({ value: undefined, label: t('noOptionSelected') });

	return (
		<div
			className={styles.dropdownBox}
			onClick={(e) => {
				e.stopPropagation();
				e.nativeEvent.stopImmediatePropagation();
			}}
		>
			<div className={styles.topBar}>
				{props.searchCallback !== undefined && (
					<SearchBar
						searchCallback={props.searchCallback}
						border
						autoFocus
					/>
				)}
				<FontAwesomeIcon
					icon={faCircleXmark}
					size='2x'
					className={styles.closeButton}
					onClick={props.closeFunction}
				/>
			</div>

			{props.options && (
				<div className={styles.optionList}>
					{options.map((option, key) => {
						let selected = false;
						if (Array.isArray(props.value))
							selected = props.value.some((value) => {
								return value === option.value;
							});
						else {
							selected = valueString === option.value;
						}

						return (
							<DropdownOption
								key={key}
								label={option.label}
								onClick={() => {
									props.onChange(option.value);
								}}
								selected={selected}
								hidden={option.hidden}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
}

function DropdownOption(props: IDropdownOptionProps) {
	if (props.hidden) return null;
	return (
		<div
			className={`${styles.dropdownOption} ${props.selected && styles.selected}`}
			onClick={props.onClick}
		>
			{props.label}
		</div>
	);
}

export default DropdownComponent;
