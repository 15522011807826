import { api } from '../../api/_Executor';
import { CompanyAnnotationQuery } from '../../business/Query/CompanyAnnotationQuery';
import { BaseQuery } from '../../business/Query/_BaseQuery';
import { AnnotationViewTableDefinition } from '../../components/DynamicTable/dynamicTable.index';
import { EntityType } from '../../globals/enums';
import { CompanyAnnotationView, ICompanyAnnotationView } from '../../models/BusinessObjects_View/CompanyAnnotationView';
import { CompanyAnnotation } from '../../models/Model/CompanyAnnotation';
import authProvider from '../../utilities/authProvider';
import { BaseViewCollectionViewModel } from './_BaseViewCollectionViewModel';
import { ICollectionViewModel } from './_collectionViewModel.interfaces';

export interface ICompanyAnnotationViewCollectionViewModel<T extends ICompanyAnnotationView, Q extends BaseQuery = CompanyAnnotationQuery>
	extends ICollectionViewModel<T, Q> {
	updateItem(index: number, text: string, refreshAll?: boolean): Promise<boolean>;
	deleteItem(index: number, refreshAll?: boolean): Promise<boolean>;
	insertItem(companyId: string, representativeId?: string, refreshAll?: boolean): Promise<boolean>;
}

export function CompanyAnnotationViewCollectionViewModel<T extends ICompanyAnnotationView, Q extends BaseQuery = CompanyAnnotationQuery>(
	instanceName: string,
	ignoreRepresentative_id?: boolean
): ICompanyAnnotationViewCollectionViewModel<T, Q> {
	const apiReader = api.companyAnnotation.viewAsync;
	//query.order = [`DESC Entity_CreatedDate`]; //LEAVE HERE (just in case)
	const query = new CompanyAnnotationQuery() as Q;
	const table = AnnotationViewTableDefinition;
	const canSearch: boolean = false;
	const canSwitch: boolean = false;
	const collectionMode = undefined;

	const cvm = BaseViewCollectionViewModel<T, Q>(instanceName, apiReader, ignoreRepresentative_id, query, undefined, 'entity_Id', 'company_Id');

	//#region ADDITIONAL_FUNCTIONS
	function generateAnnotation(companyId: string, representativeId?: string) {
		let annotation = new CompanyAnnotation();
		annotation.$type = EntityType.companyAnnotation;
		annotation.company_Id = companyId;

		if (representativeId === undefined) {
			representativeId = authProvider.getUserId();
		}

		annotation.createdBy_Id = representativeId ?? null;

		return annotation;
	}

	function convertAnnotationToView(annotation: CompanyAnnotation) {
		let view = new CompanyAnnotationView();

		view.entity_Id = annotation.id;
		view.entity_Company_Id = annotation.company_Id;
		view.entity_CreatedBy_Id = annotation.createdBy_Id;
		view.entity_Text = annotation.text;

		//Not sure if needed
		view.entity_CreatedDate = annotation.createdDate;
		view.entity_LasteModifiedDate = annotation.lastModifiedDate;

		return view;
	}

	function convertViewToAnnotation(view: CompanyAnnotationView) {
		let annotation = new CompanyAnnotation();
		annotation.id = view.entity_Id;
		annotation.company_Id = view.entity_Company_Id;
		annotation.createdBy_Id = view.entity_CreatedBy_Id;
		annotation.text = view.entity_Text;

		//Not sure if needed:
		annotation.createdDate = view.entity_CreatedDate;
		annotation.lastModifiedDate = view.entity_LasteModifiedDate;

		return annotation;
	}

	async function deleteItem(index: number, refreshAll: boolean = false) {
		if (cvm.items.length === 0) return true; //No error
		let item = cvm.items[index];
		if (item === undefined) return false; //Can't find

		let success = await api.companyAnnotation.deleteByIdAsync(item.entity_Id!);
		if (success === undefined) return false;

		//Refresh the whole list?
		if (refreshAll) {
			return await cvm.doQuery();
		}

		// Create a copy of the array without the deleted item
		const updatedItems = cvm.items.filter((_, i) => i !== index);

		// Update the state
		cvm.setItems(updatedItems);

		return true;
	}

	async function insertItem(companyId: string, representativeId?: string, refreshAll?: boolean) {
		let generatedAnnotation = generateAnnotation(companyId, representativeId);
		let annotation = await api.companyAnnotation.updateAsync(generatedAnnotation);
		if (annotation === undefined) return false;

		//Refresh the whole list?
		if (refreshAll) {
			return await cvm.doQuery();
		}

		//Convert and insert new listItem
		let view = convertAnnotationToView(annotation);

		//Insert at index 0
		cvm.items.splice(0, 0, view as T); //deliberately not using setItems
		cvm.setItems([...cvm.items]);

		return true;
	}

	async function updateItem(index: number, text: string, refreshAll: boolean = false): Promise<boolean> {
		if (cvm.items.length === 0) return true; //No error
		let view = cvm.items[index];
		if (view === undefined) return false; //Can't find

		//set new text
		view.entity_Text = text;

		let convertedAnnotation = convertViewToAnnotation(view);
		let updatedAnnotation = await api.companyAnnotation.updateAsync(convertedAnnotation);
		if (updatedAnnotation === undefined) return false;

		//Refresh the whole list?
		if (refreshAll) {
			return await cvm.doQuery();
		}

		//Only update the modified listItem
		view.entity_Text = updatedAnnotation.text;
		cvm.setItems([...cvm.items]); //unfortunately, needed to trigger a refresh

		return true;
	}
	//#endregion ADDITIONAL_FUNCTIONS

	return {
		instanceName,
		isSearchable: canSearch,
		isSwitchable: canSwitch,
		read: cvm.doRead,
		pageNext: cvm.doPageNext,
		switchRepresentative: cvm.doSwitchRepresentative,
		refresh: cvm.doQuery,
		getProperties: cvm.getProperties,
		toggleSortOrder: cvm.toggleSortOrder,
		pkName: cvm.pkName,
		skName: cvm.skName,
		items: cvm.items,
		query: cvm.query,
		defaultTable: table,

		search: cvm.doSearch,
		switchMode: cvm.doSwitchMode,
		readAll: cvm.doReadAll,
		searchTerm: cvm.searchTerm,
		mode: cvm.mode,
		modeEnum: collectionMode,

		//Additional
		updateItem,
		deleteItem,
		insertItem,
	};
}
