import { api } from '../../../api/_Executor';
import {
	PrioritizedCompanyCollectionMode,
	PrioritizedCompanyViewCollectionViewModel,
} from '../../../viewmodels/Collections/PrioritizedCompanyViewCollectionViewModel';
import { RepresentativeViewCollectionViewModel } from '../../../viewmodels/Collections/RepresentativeViewCollectionViewModel';
import { OpportunityStatusReasonCollectionViewModel } from '../../../viewmodels/Collections/OpportunityStatusReasonCollectionViewModel';
import { ExchangeSynchronizationStatus, OpportunityStatus } from '../../../model/_Enums';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { IOption } from '../inputField.types';
import { AddressViewCollectionViewModel } from '../../../viewmodels/Collections/AddressViewCollectionViewModel';
import { ContactViewCollectionViewModel } from '../../../viewmodels/Collections/ContactViewCollectionViewModel';
import { CompanyGroupViewCollectionViewModel } from '../../../viewmodels/Collections/CompanyGroupCollectionViewModel';

export enum DropdownEntityType {
	companies,
	companyGroup,
	companyType,
	activeRepresentatives,
	address,
	addressTypes,
	appointmentTypes,
	contact,
	countries,
	exchangeSynchronizationStatuses,
	opportunityStatuses,
	opportunityStatusReasons,
	visitingFrequencies,
}

export enum DictionaryEntryType {
	CVM,
	redux,
	enum,
	default,
}

export interface IOptionPropertyNames {
	valuePropertyName: string;
	labelPropertyName: string | string[];
}

export interface ICVMDictionaryEntry extends IOptionPropertyNames {
	type: DictionaryEntryType.CVM;
	entityDetailPopupType?: EntityType;
	cvm: any;
	cvmCollectionMode?: number;
	getEntityFunction: (id) => Promise<any>;
	getEntityOptionPropNames?: IOptionPropertyNames;
	appSelectorCallback?: (state) => any;
}

export interface IReduxDictionaryEntry extends IOptionPropertyNames {
	type: DictionaryEntryType.redux;
	appSelectorCallback: (state) => any;
}

export interface IEnumDictionaryEntry {
	type: DictionaryEntryType.enum;
	enum: any;
	labelPrefix?: string;
}

export interface IDefaultDictionaryEntry {
	type: DictionaryEntryType.default;
	options: IOption[];
}

export const dropdownEntityTypeDictionary: Record<
	DropdownEntityType,
	ICVMDictionaryEntry | IReduxDictionaryEntry | IEnumDictionaryEntry | IDefaultDictionaryEntry
> = {
	[DropdownEntityType.companies]: {
		type: DictionaryEntryType.CVM,
		entityDetailPopupType: EntityType.Company,
		cvm: PrioritizedCompanyViewCollectionViewModel,
		cvmCollectionMode: PrioritizedCompanyCollectionMode.all,
		valuePropertyName: 'entity_Id',
		labelPropertyName: 'entity_Name',
		getEntityFunction: api.company.getByIdAsync,
		getEntityOptionPropNames: {
			valuePropertyName: 'id',
			labelPropertyName: 'name',
		},
	},
	[DropdownEntityType.companyGroup]: {
		type: DictionaryEntryType.CVM,
		cvm: CompanyGroupViewCollectionViewModel,
		valuePropertyName: 'id',
		labelPropertyName: 'code',
		getEntityFunction: api.companyGroup.getByIdAsync,
	},
	[DropdownEntityType.companyType]: {
		type: DictionaryEntryType.default,
		options: [
			{ value: 'account', label: 'Account' },
			{ value: 'lead', label: 'Lead' },
		],
	},
	[DropdownEntityType.activeRepresentatives]: {
		type: DictionaryEntryType.CVM,
		cvm: RepresentativeViewCollectionViewModel,
		valuePropertyName: 'id',
		labelPropertyName: 'fullName',
		getEntityFunction: api.representative.getByIdAsync,
	},
	[DropdownEntityType.address]: {
		type: DictionaryEntryType.CVM,
		cvm: AddressViewCollectionViewModel,
		valuePropertyName: 'id',
		labelPropertyName: ['street', 'city'],
		getEntityFunction: api.address.getByIdAsync,
	},
	[DropdownEntityType.addressTypes]: {
		type: DictionaryEntryType.redux,
		appSelectorCallback: (state) => state.globals.addressTypes,
		valuePropertyName: 'id',
		labelPropertyName: ['code', 'summary'],
	},
	[DropdownEntityType.appointmentTypes]: {
		type: DictionaryEntryType.redux,
		appSelectorCallback: (state) => state.globals.appointmentTypes,
		valuePropertyName: 'id',
		labelPropertyName: 'name',
	},
	[DropdownEntityType.contact]: {
		type: DictionaryEntryType.CVM,
		cvm: ContactViewCollectionViewModel,
		valuePropertyName: 'id',
		labelPropertyName: ['firstName', 'surname'],
		getEntityFunction: api.contact.getByIdAsync,
	},
	[DropdownEntityType.countries]: {
		type: DictionaryEntryType.redux,
		appSelectorCallback: (state) => state.globals.countries,
		valuePropertyName: 'id',
		labelPropertyName: ['code', 'name'],
	},
	[DropdownEntityType.exchangeSynchronizationStatuses]: {
		type: DictionaryEntryType.enum,
		enum: ExchangeSynchronizationStatus,
	},
	[DropdownEntityType.opportunityStatuses]: {
		type: DictionaryEntryType.enum,
		enum: OpportunityStatus,
		labelPrefix: 'opportunityStatus.',
	},
	[DropdownEntityType.opportunityStatusReasons]: {
		type: DictionaryEntryType.CVM,
		cvm: OpportunityStatusReasonCollectionViewModel,
		valuePropertyName: 'id',
		labelPropertyName: 'description',
		getEntityFunction: api.opportunityStatusReason.getByIdAsync,
	},
	[DropdownEntityType.visitingFrequencies]: {
		type: DictionaryEntryType.redux,
		appSelectorCallback: (state) => state.globals.visitingFrequencies,
		valuePropertyName: 'id',
		labelPropertyName: ['code', 'description'],
	},
};

export interface IBoundDropDownInputProps {
	DDEntityType: DropdownEntityType;
	value: any;
	onChange: (value: any) => void;
	readOnly?: boolean;
	hasError?: boolean;
	readId?: any;
}

export interface IReduxBoundDropDownInputProps extends IReduxDictionaryEntry, IBoundDropDownInputProps {}

export interface ICVMBoundDropDownInputProps extends ICVMDictionaryEntry, IBoundDropDownInputProps {}
