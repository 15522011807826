import React, { Component, Fragment, MouseEventHandler } from 'react';
import styles from './Button.module.scss';

import { Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class Button extends Component<{
	i18nKey: string;
	theme?: 'confirm' | 'border' | 'default';
	onClick?: MouseEventHandler;
	disabled?: boolean;
	loading?: boolean;
	width?: number;
	textAlign?: 'left' | 'right' | 'center';
	children?: React.ReactNode;
}> {
	render() {
		const classes = [styles.button];
		if (this.props.loading) classes.push(styles.loading);
		if (this.props.theme === 'confirm') classes.push(styles.confirmTheme);
		else if (this.props.theme === 'border') classes.push(styles.borderTheme);

		return (
			<button
				onClick={this.props.onClick}
				disabled={this.props.loading || this.props.disabled}
				className={classes.join(' ')}
				style={{ width: this.props.width, textAlign: this.props.textAlign }}
			>
				<div>
					<Fragment>
						{this.props.children === undefined ? <Trans i18nKey={this.props.i18nKey} /> : this.props.children}
						{this.props.loading && <Spinner size={24} />}
					</Fragment>
				</div>
			</button>
		);
	}
}

export class Spinner extends React.Component<
	{
		size?: number;
	},
	any
> {
	render() {
		return (
			<FontAwesomeIcon
				className={styles.spinner}
				icon="spinner"
				style={{ width: this.props.size, height: this.props.size }}
			/>
		);
	}
}
