import { useEffect, useState, Fragment, useCallback, useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { exportProperties, getEntities, getEntityDefinition } from '../../../api/ExportAPI';
import { GetRepresentative } from '../../../api/RepresentativesAPI';
import { RepresentativeViewCollectionViewModel } from '../../../viewmodels/Collections/RepresentativeViewCollectionViewModel';
import { Languages } from '../../../localization/I18n';
import { closeDialog, openConfirm } from '../../../components/Dialog/dialogSlice';
import { GridComponents } from '../../../components';
import styles from './export.module.scss';
import { EntityTreeView } from './EntityTreeView';
import { removeProperties } from './removeProperties';

export const ExportPage = () => {
	const instanceName = 'export';
	const representativeData = RepresentativeViewCollectionViewModel(instanceName);

	const location = useLocation();
	const match = useMatch(location.pathname);
	const dispatch = useDispatch();
	const translation = useTranslation();

	const currentLanguage = useMemo(() => Languages.find((Language) => Language.locale === translation.i18n.language), [translation.i18n.language]);

	const [repInfo, setRepInfo] = useState<{ emailAddress: string }>({ emailAddress: '' });
	const [loading, setLoading] = useState(false);
	const [checkedItems, setCheckedItems] = useState<Array<string>>([]);
	const [entities, setEntities] = useState<[{ $type: string; DisplayName: string; EntityType: string }]>([{ $type: '', DisplayName: '', EntityType: '' }]);
	const [filesToExport, setFilesToExport] = useState<[{ $type?: string; DisplayName?: string; PropertyName?: string; RelationPath?: string }] | any>([]);
	const [selectedEntityType, setSelectedEntityType] = useState('');
	const [entityDefinition, setEntityDefinition] = useState<{
		$type: string;
		DisplayName: string;
		EntityType: string;
		Properties: Array<{ DisplayName: string; RelationPath: string; $type: string }>;
	}>({ $type: '', DisplayName: '', EntityType: '', Properties: [] });

	const getRepresentativeInfo = useCallback(async () => {
		const response = await GetRepresentative(representativeData?.query.representative_Id);
		setRepInfo(await response.json());
	}, [representativeData?.query.representative_Id]);

	useEffect(() => {
		if (representativeData?.query.representative_Id) {
			getRepresentativeInfo();
		}
	}, [representativeData?.query.representative_Id, getRepresentativeInfo]);

	const getAllEntities = useCallback(async () => {
		const response = await getEntities(currentLanguage?.fullLocale);
		setEntities(await response.json());
	}, [currentLanguage]);

	useEffect(() => {
		getAllEntities();
	}, [getAllEntities]);

	const getAllEntityDefinition = useCallback(async () => {
		const response = await getEntityDefinition({ entityType: selectedEntityType, depth: 4 }, currentLanguage?.fullLocale);
		setEntityDefinition(await response.json());
	}, [selectedEntityType, currentLanguage]);

	useEffect(() => {
		if (selectedEntityType !== '') {
			getAllEntityDefinition();
		}
	}, [selectedEntityType, currentLanguage, getAllEntityDefinition]);

	const exportFiles = useCallback(async () => {
		setLoading(true);
		const filesToSend = removeProperties(
			filesToExport.filter((file) => file.items.length === 0),
			['items', 'text']
		);

		const response = await exportProperties(selectedEntityType, filesToSend, currentLanguage?.fullLocale);

		if (response.status === 200) {
			const dialogText = t('exportEmail') + ' ' + repInfo.emailAddress;

			dispatch(
				openConfirm({
					title: 'export',
					content: <div className={styles.dialogContent}>{dialogText}</div>,
					confirmButtons: [
						{
							onClick: closeDialog,
							confirmText: 'ok',
						},
					],
				})
			);
		}
		setLoading(false);
		setFilesToExport([]);
		setCheckedItems([]);
	}, [selectedEntityType, filesToExport, currentLanguage, dispatch, repInfo.emailAddress]);

	return (
		<Fragment>
			<GridComponents.Column size="half">
				<GridComponents.Row.Header content={<GridComponents.Row.Breadcrumb match={match} />} />
				<GridComponents.Row.SubHeader>
					<GridComponents.Row.SubHeaderDetail
						icon="city"
						name={t('export')}
					/>
				</GridComponents.Row.SubHeader>
				<GridComponents.Row.Scroll>
					<div className={styles.subtitle}>{selectedEntityType ? t('selectAndClick') : t('selectToExport')}</div>
					<div className={styles.selectedEntitiesWrapper}>
						<EntityTreeView
							entityDefinition={entityDefinition}
							exportFiles={exportFiles}
							setFilesToExport={setFilesToExport}
							checkedItems={checkedItems}
							setCheckedItems={setCheckedItems}
							loading={loading}
						/>
					</div>
				</GridComponents.Row.Scroll>
			</GridComponents.Column>
			<GridComponents.Column size="fourth">
				<GridComponents.Row.Header
					content={
						<Fragment>
							<Trans i18nKey="exportSelectType" />
						</Fragment>
					}
				/>
				<>
					<GridComponents.Row.Scroll>
						<div className={styles.entitiesWrapper}>
							{entities?.map((entity) => {
								return (
									<div
										className={`${styles.sideEntity} ${selectedEntityType === entity?.EntityType ? styles.activeEntity : ''}`}
										key={entity?.DisplayName}
										onClick={() => {
											setFilesToExport([]);
											setCheckedItems([]);
											setSelectedEntityType(entity?.EntityType);
										}}
									>
										{entity?.DisplayName ? entity?.DisplayName : ''}
									</div>
								);
							})}
						</div>
					</GridComponents.Row.Scroll>
				</>
			</GridComponents.Column>
		</Fragment>
	);
};
