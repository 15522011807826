/* UNMODIFIED */
import { ModelBase, IModelBase } from "../AbstractModel/ModelBase";
import { IRepresentative } from "./Representative";

export interface IAnnotation extends IModelBase {
	text: string | null;
    createdBy: IRepresentative | null;
    createdBy_Id: string | null;
}

export class Annotation extends ModelBase implements IAnnotation {
	public text: string | null = null;
    public createdBy: IRepresentative | null = null;
    public createdBy_Id: string | null = null;
}
