import { useCallback, useEffect } from 'react';
import styles from './entityListView.module.scss';
import { BoundListView } from './BoundListView/BoundListView';
import { listViewConfigurations } from './entityListView.configs';
import EventBus from '../../utilities/EventBus';
import { CrudType, EntityType } from '../EntityDetailPopup/entityDetailPopup.enums';

export function EntityListView(props: { entityType: EntityType; hidePlusButton?: boolean }) {
	const configs = listViewConfigurations[props.entityType];
	const instanceName = EntityType[props.entityType];
	const vm = configs.viewModel(instanceName);

	const handleViewModelEvent = useCallback(
		(args) => {
			const crudType = args.detail.crudType;

			if (crudType && crudType !== CrudType.Read) {
				//TODO, remember scrollposition?
				vm.refresh().catch((e) => console.warn(e));
			}
		},
		[vm]
	);

	const cvmRead = useCallback(() => {
		vm.read();
		EventBus.instance().on(configs.collectionVMInstanceName, handleViewModelEvent);
	}, [configs.collectionVMInstanceName]);

	return (
		<div className={styles.container}>
			<BoundListView
				cvm={vm}
				cvmRead={cvmRead}
				tableAttributes={configs.tableAttributes}
				entityType={props.entityType}
				useCustomFields
				// idPropName={configs.idPropName}
				VMInstanceName={instanceName}
				headerText={configs.headerText}
				hidePlusButton={props.hidePlusButton}
				checkEmphasisFunction={configs.checkEmphasisFunction}
			/>
		</div>
	);
}
