import { Dispatch, SetStateAction } from 'react';
import { TaskQueryBase } from '../../business/Query/TaskQuery';
import { BaseQuery } from '../../business/Query/_BaseQuery';
import { ITableAttribute } from '../../components/DynamicTable/DynamicTable.types';
import { EnumTypeNumber } from '../../globals/types';

//MAYBE create an Editable ICollectionViewModel?
export interface ICollectionViewModel<T, Q extends BaseQuery> {
	instanceName: string;
	isSearchable: boolean;
	isSwitchable: boolean;

	read: (id?: string) => Promise<boolean>;
	pageNext: () => Promise<boolean>;
	refresh: () => Promise<boolean>;
	search: (s: string) => Promise<boolean>;
	switchMode: (modus: number) => Promise<boolean>;
	switchRepresentative: () => Promise<boolean>;
	readAll: (modus: number) => Promise<boolean>; //WARNING, for testing
	getProperties: () => { [P in keyof T]?: P | undefined };
	toggleSortOrder: (propertyName: string) => void;

	pkName: string;
	skName: string;
	items: T[];
	query: Q;
	defaultTable: any;
	searchTerm: string;
	mode: number;
	modeEnum: any;
}

export interface IBaseCollectionViewModel<T, Q extends BaseQuery> {
	doSwitchMode: (modus: number) => Promise<boolean>;

	doRead: (id?: string) => Promise<boolean>;
	doReadAll: (modus?: number) => Promise<boolean>;

	doPageNext: () => Promise<boolean>;
	doSearch: (s: string) => Promise<boolean>;
	doQuery: (q?: Q) => Promise<boolean>;
	doSwitchRepresentative: () => Promise<boolean>;

	toggleSortOrder: (propertyName: string) => void;

	pkName: string;
	skName: string;
	query: Q;
	items: T[];
	/** DON'T USE setItems UNDER NORMAL CIRCUMSTANCES ! */
	setItems: Dispatch<SetStateAction<T[]>>;
	mode: number;
	searchTerm: string;
	defaultMode?: number;

	getProperties: () => { [P in keyof T]?: P | undefined };
	getRepresentativeId();
}

export enum orderStringEnum {
	ASC = 'ASC',
	DESC = 'DESC',
}

export interface iSortOrderObject {
	order: orderStringEnum | undefined;
	propName: string;
}
