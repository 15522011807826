import styles from './inputFields.module.scss';
import { IDateTimeInputProps } from './inputField.types';
import { Fragment } from 'react';

export function DateTimeInput(props: IDateTimeInputProps) {
	const value = convertDefaultValue(props.type, props.value);
	if (value === '' && props.readOnly) {
		return (
			<input
				className={styles.dateTimeInput}
				readOnly
				value='-'
			/>
		);
	} else
		return (
			<Fragment>
				<input
					className={`${styles.input} ${styles.dateTimeInput} ${props.hasError && styles.invalid}`}
					required={props.required}
					type={props.type}
					value={convertDefaultValue(props.type, props.value)}
					onChange={(e) => props.onChange(e.target.value)}
					readOnly={props.readOnly}
				/>
			</Fragment>
		);
}

//FIXME: Change localeStrings so we no longer use a "arbitrary" locale to split date and time
function convertDefaultValue(type: 'date' | 'time' | 'datetime-local', defaultValue: string): string {
	try {
		if (defaultValue === null || defaultValue === undefined) return '';

		const dateObj = new Date(defaultValue);
		if (dateObj.toString() === 'Invalid Date' || !defaultValue) return '';

		const localeStrings = dateObj
			.toLocaleString('sv-SE', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				hourCycle: 'h23',
			})
			.split(' ');
		/*toLocaleString() will produce a string like: '2022-12-15, 06:40'. 
		Only the locales: en-CA, fr-CA, & sv-SE write the dates in YYYY-MM-DD, 
		other locales don't use hyphens (-) and/or write the year last.*/
		const dateTime = {
			date: localeStrings[0],
			time: localeStrings[1],
		};

		switch (type) {
			case 'date':
				return checkDateString(dateTime.date);
			case 'time':
				return dateTime.time;
			case 'datetime-local':
				return localeStrings.join('T');
		}
	} catch (e) {
		console.error(e, 'dateTime type:', type, 'defaultValue:', defaultValue);
		return defaultValue;
	}
}

function checkDateString(dateString): string {
	let [year, month, day] = dateString.split('-');
	const yearLength = year.length;

	for (let i = 4; i > yearLength; i--) {
		year = '0' + year;
	}

	const newDateString = `${year}-${month}-${day}`;

	return newDateString;
}
